import { css, styled } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { StorageUnitStatus } from 'types/generated/graphql';

type Variant = string | undefined;

// Create a union type for valid filter options
type StatusBadgeVariants =
  | 'EMPTY'
  | 'OCCUPIED'
  | 'EXPIRED'
  | 'BLOCKING'
  | typeof StorageUnitStatus.Usable
  | typeof StorageUnitStatus.Blocked
  | typeof StorageUnitStatus.Broken;

// Define the enum with the valid options
export const StorageUnitFilterOptionsEnum: Record<
  StatusBadgeVariants,
  StatusBadgeVariants
> = {
  EMPTY: 'EMPTY',
  OCCUPIED: 'OCCUPIED',
  EXPIRED: 'EXPIRED',
  BLOCKING: 'BLOCKING',
  USABLE: StorageUnitStatus.Usable,
  BLOCKED: StorageUnitStatus.Blocked,
  BROKEN: StorageUnitStatus.Broken,
};

type StatusBadgeProps = {
  title: StatusBadgeVariants | string;
  testId?: string;
};

const StatusBadge = ({ title, testId }: StatusBadgeProps) => {
  const { t } = useTranslation();

  function getStatus(title: string) {
    const statuses = ['Occupied', 'Empty', 'Expired', 'Blocking'];
    const match = statuses.find((status) =>
      new RegExp(`\\b${status}\\b`, 'g').test(title)
    );
    return match ? match.toUpperCase() : title;
  }

  return (
    <Badge data-test={testId} variant={getStatus(title)}>
      {t(title)}
    </Badge>
  );
};

const Badge = styled.span<{ variant: Variant }>`
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  ${({ variant, theme }) => {
    switch (variant) {
      case StorageUnitFilterOptionsEnum.EMPTY:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.success};
        `;
      case StorageUnitFilterOptionsEnum.OCCUPIED:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.warning};
        `;
      case StorageUnitFilterOptionsEnum.EXPIRED:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.error};
        `;
      case StorageUnitFilterOptionsEnum.BLOCKING:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.secondary.grey250};
        `;
      case StorageUnitFilterOptionsEnum.USABLE:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.success};
        `;
      case StorageUnitFilterOptionsEnum.BLOCKED:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.error};
        `;
      case StorageUnitFilterOptionsEnum.BROKEN:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.error};
        `;
    }
  }}
`;

export default StatusBadge;
