import { ContegUiLanguage, TranslationContext } from '@conteg/ui';
import { useTranslation } from 'react-i18next';

export const ContegUiTranslationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { i18n } = useTranslation();

  const mappedLang: ContegUiLanguage =
    i18n.language === ContegUiLanguage.cs
      ? ContegUiLanguage.cs
      : ContegUiLanguage.en;

  return (
    <TranslationContext.Provider value={mappedLang}>
      {children}
    </TranslationContext.Provider>
  );
};
