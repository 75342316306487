import { Box, Text } from '@conteg/ui';
import {
  OpenModal,
  OpenModalTypes,
} from 'pages/storage-unit-detail/components/actions/allocation-actions';
import ForcedOpenUnitWithReservation from 'pages/storage-unit-detail/components/actions/open-unit/forced-open-unit-with-reservation';
import { useTranslation } from 'react-i18next';
import { StorageUnitListFragment } from 'types/generated/graphql';

type ActionsModalContentProps = {
  contentType: OpenModal;
  storageUnit: StorageUnitListFragment;
};

const ActionsModalContent = ({
  contentType,
  storageUnit,
}: ActionsModalContentProps) => {
  const { t } = useTranslation();

  switch (contentType) {
    case OpenModalTypes.ForcedOpenStorageUnitWithReservation:
      return (
        <ForcedOpenUnitWithReservation
          storageUnitId={storageUnit.storageUnit.id}
        />
      );
  }

  if (contentType === null) return null;

  return (
    <Box textAlign="center">
      <Text
        testId="modal-content-error"
        variant="highlight"
        content={t('Page.StorageUnit.Actions.ModalError')}
      />
    </Box>
  );
};

export default ActionsModalContent;
