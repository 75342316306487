import { useUserData } from '@conteg/auth';
import {
  Button,
  ErrorDetailInfo,
  Flex,
  Heading,
  Input,
  InputTypes,
  Loading,
  Option,
  Select,
  showAlertModalError,
} from '@conteg/ui';
import Notification from 'components/notification/notification';
import { CUSTOM_AUTH_TOKEN } from 'config';
import { SHA256 } from 'crypto-js';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  LockStatus,
  ReservationStatus,
  ServiceType,
  ServiceTypeEnum,
  StorageUnitDocument,
  useOpenStorageUnitMutation,
  useTenantsQuery,
  useVerifyAccessPinMutation,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useOnLockChangeHandler } from 'utils/hooks/storage-units-event-handlers';
import { useGetOtp } from 'utils/hooks/use-get-otp';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';
import { logger } from 'utils/logging/init';

import EndCurrentReservation from '../end-current-reservation';
import ShowOneTimePassword from '../one-time-password/show-one-time-password';

type FormInputs = {
  accessPin: string;
  tenantSubjectId?: string;
  projectId?: string;
  serviceType?: string;
};

const OpenWithPin = ({
  storageUnitId,
  reservationStatus,
  openWithoutVerifyStep,
  tenantSubjectId,
  serviceType,
}: {
  openWithoutVerifyStep: boolean;
  storageUnitId?: string;
  reservationStatus?: ReservationStatus | null | undefined;
  tenantSubjectId?: string;
  serviceType?: ServiceTypeEnum | ServiceType;
}) => {
  const { t } = useTranslation();

  const userData = useUserData(CUSTOM_AUTH_TOKEN);
  const userId = userData?.userId;

  const { pointId } = usePoint();
  const onLockChange = useOnLockChangeHandler();

  const invalidate = useInvalidateQueries();

  const { mutateAsync: openStorageUnit, isPending: isOpening } =
    useOpenStorageUnitMutation();

  const { mutateAsync: verifyAccessPin, isPending: isVerifying } =
    useVerifyAccessPinMutation();

  const [bindingFee, setBindingFee] = useState<number>(0);
  const [formNotice, setFormNotice] = useState<string>('');
  const [storageUnitIdResponse, setStorageUnitIdResponse] =
    useState<string>('');

  const [accessPin, setAccessPin] = useState<string>('');
  const [isAccessPinVerified, setAccessPinVerified] = useState<boolean>(false);
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [showOptButton, setShowOptButton] = useState<boolean>(true);
  const [showEndReservation, setShowEndReservation] = useState<boolean>(false);

  const { otp, setStartFetching } = useGetOtp(
    storageUnitId ?? '',
    reservationStatus
  );

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      accessPin: '',
      projectId: '',
      serviceType: '',
    },
  });

  const {
    data: tenants,
    isLoading,
    error: tenantsError,
  } = useTenantsQuery(
    {
      pointId: pointId as string,
    },
    {
      enabled: !!pointId,
      gcTime: 0,
      staleTime: 0,
      select: (data) => data.tenants,
    }
  );

  // This is for opening storage unit from list of storage units, when user selects tenant from select box to open storage unit
  const options: Option[] =
    tenants?.map((data) => ({
      label: data?.tenantName ?? '',
      value: data?.tenantSubjectId ?? '',
    })) ?? [];

  const watchTenantSubjectId = watch('tenantSubjectId');

  useEffect(() => {
    if (watchTenantSubjectId && tenants) {
      const selectedTenant = tenants.find(
        (tenant) => tenant?.tenantSubjectId === watchTenantSubjectId
      );

      if (selectedTenant) {
        setValue('projectId', selectedTenant.projectId);
        setValue('serviceType', selectedTenant.tenantServiceType);
      }
    }
  }, [watchTenantSubjectId, setValue, tenants]);

  const handleVerifyAccessPin: SubmitHandler<FormInputs> = async ({
    accessPin,
  }) => {
    setFormNotice('');
    const verifyAccessPinData = {
      accessPin,
      pointId: pointId as string,
      tenantSubjectId: tenantSubjectId as string,
      storageUnitId: storageUnitId as string,
      projectId: tenants?.[0]?.projectId as string,
      serviceType: serviceType as ServiceTypeEnum,
    };
    await verifyAccessPin(verifyAccessPinData, {
      onSuccess: ({ verifyAccessPin }) => {
        if (verifyAccessPin.bindingFee) {
          setFormNotice('Page.StorageUnit.Actions.Open.BindingFee.Error');
          setBindingFee(verifyAccessPin.bindingFee);
          logger.info(
            `Calling mutation verifyAccessPin with variables ${JSON.stringify({
              ...verifyAccessPinData,
              accessPin: SHA256(accessPin).toString(),
            })}`
          );
        } else {
          setFormNotice('Page.StorageUnit.Actions.Open.Success');
          setAccessPin(accessPin);
          setAccessPinVerified(true);
        }

        reset();
      },
      onError: (error) => {
        showAlertModalError(
          t('Page.StorageUnit.Actions.Open.Pin.Error'),
          error
        );
        setAccessPin('');
        setAccessPinVerified(false);
        logger.error(
          `Calling mutation verifyAccessPin resolved in error with variables ${JSON.stringify(
            {
              ...verifyAccessPinData,
              accessPin: SHA256(accessPin).toString(),
            }
          )}`
        );
      },
    });
  };

  const handleOpenStorageUnitWithoutVerifyStep: SubmitHandler<
    FormInputs
  > = async ({ accessPin, projectId, serviceType, tenantSubjectId }) => {
    setFormNotice('');

    if (!pointId || !tenantSubjectId || !projectId || !serviceType) {
      throw new Error(
        `Missing required data: ${JSON.stringify({ pointId, tenantSubjectId, projectId, serviceType })}`
      );
    }
    const endUserData = {
      pointId,
      userId: userId as string,
      storageUnitId,
      tenantSubjectId,
      endUser: { accessPin },
      isBlockingReservation: false,
      projectId,
      serviceType: serviceType as ServiceTypeEnum,
      singleTenantVerification: true,
    };

    await openStorageUnit(endUserData, {
      onSuccess: ({ openStorageUnit }) => {
        if (openStorageUnit.bindingFee) {
          setFormNotice('Page.StorageUnit.Actions.Open.BindingFee.Error');
          setBindingFee(openStorageUnit.bindingFee);
          setShowEndReservation(false);
        } else {
          setFormNotice('Page.StorageUnit.Actions.Open.Success');
          setShowEndReservation(true);
          setStorageUnitIdResponse(openStorageUnit.storageUnitId);

          invalidate([StorageUnitDocument]);

          if (onLockChange)
            onLockChange(openStorageUnit.storageUnitId, LockStatus.Opened);
        }

        reset();
      },
      onError: (error) => {
        showAlertModalError(
          t('Page.StorageUnit.Actions.Open.Pin.Error'),
          error
        );
      },
    });
  };

  const handleOpenStorageUnit = async () => {
    setFormNotice('');

    const projectId = tenants?.[0]?.projectId;

    if (
      !pointId ||
      !storageUnitId ||
      !tenantSubjectId ||
      !projectId ||
      !serviceType
    ) {
      throw new Error(
        `Missing required data: ${JSON.stringify({ pointId, storageUnitId, tenantSubjectId, projectId, serviceType })}`
      );
    }

    const openStorageUnitData = {
      pointId,
      userId: userId as string,
      storageUnitId,
      tenantSubjectId,
      endUser: { accessPin },
      isBlockingReservation: false,
      projectId,
      serviceType: serviceType as ServiceTypeEnum,
      singleTenantVerification: true,
    };

    await openStorageUnit(openStorageUnitData, {
      onSuccess: ({ openStorageUnit }) => {
        if (openStorageUnit.bindingFee) {
          setFormNotice('Page.StorageUnit.Actions.Open.BindingFee.Error');
          setBindingFee(openStorageUnit.bindingFee);
          setShowEndReservation(false);
          logger.info(
            `Calling mutation verifyAccessPin resulting with bindingFee and variables ${JSON.stringify(
              {
                ...openStorageUnitData,
                accessPin: SHA256(accessPin).toString(),
              }
            )}`
          );
        } else {
          setFormNotice('Page.StorageUnit.Actions.Open.Success');
          setStorageUnitIdResponse(openStorageUnit.storageUnitId);
          setShowEndReservation(true);

          invalidate([StorageUnitDocument]);

          logger.info(
            `Calling mutation verifyAccessPin with variables ${JSON.stringify({
              ...openStorageUnitData,
              accessPin: SHA256(accessPin).toString(),
            })}`
          );
          if (onLockChange)
            onLockChange(openStorageUnit.storageUnitId, LockStatus.Opened);
        }

        reset();
      },
      onError: () => {
        setFormNotice('Page.StorageUnit.Actions.Open.Pin.Error');
        logger.error(
          `Calling mutation verifyAccessPin resolved in error with variables ${JSON.stringify(
            {
              ...openStorageUnitData,
              accessPin: SHA256(accessPin).toString(),
            }
          )}`
        );
      },
    });
  };

  const handleClickShowOtp = useCallback(() => {
    setStartFetching(true);
    setShowOtp(true);
    setShowOptButton(false);
  }, [setStartFetching, setShowOtp, setShowOptButton]);

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loading text={t('Loading')} />
      </Flex>
    );
  }

  if (tenantsError) {
    return (
      <ErrorDetailInfo
        error={tenantsError}
        title={t('Error.TenantsLoadError')}
      />
    );
  }

  return (
    <>
      {openWithoutVerifyStep ? (
        <>
          <form onSubmit={handleSubmit(handleOpenStorageUnitWithoutVerifyStep)}>
            <Flex
              flexDirection="column"
              gap="6rem"
              alignItems="center"
              mt="6rem"
            >
              {formNotice && (
                <Notification
                  testId="open-with-pin"
                  message={formNotice}
                  translationParams={
                    bindingFee ? { fee: bindingFee.toString() } : undefined
                  }
                />
              )}
              <Controller
                control={control}
                name="tenantSubjectId"
                render={({ field: { value, onChange } }) => (
                  <Select
                    hasAutocomplete={false}
                    width="100%"
                    testId="select-tenantSubjectId"
                    defaultSingleValue={value}
                    userSingleValue={value}
                    label={t(
                      'Page.StorageUnit.Actions.BlockingReservation.Select.Tenant'
                    )}
                    onSingleValueChange={onChange}
                    options={options}
                    error={
                      errors.tenantSubjectId?.message &&
                      t(errors.tenantSubjectId.message)
                    }
                    disabled={false}
                    isNullable={false}
                  />
                )}
              />
              <Input
                testId="open-with-pin-pin"
                type={InputTypes.PASSWORD}
                label={t('Page.StorageUnit.Actions.Open.Pin')}
                placeholder={t('Page.StorageUnit.Actions.Open.Pin')}
                error={errors.accessPin?.message && t(errors.accessPin.message)}
                {...register('accessPin', { required: 'Error.Form.Required' })}
              />
              <Button
                testId="open-with-pin-button"
                variant="primary"
                size="xl"
                title={t('Page.StorageUnit.Actions.Open')}
                disabled={isOpening}
                type="submit"
              />
            </Flex>
          </form>
          {showEndReservation ? (
            <Flex justifyContent="center" mt="4rem">
              <EndCurrentReservation storageUnitId={storageUnitIdResponse} />
            </Flex>
          ) : null}
        </>
      ) : (
        <>
          {isAccessPinVerified ? (
            <Flex flexDirection="column" gap="5rem">
              <Flex alignItems="center" gap="5rem">
                <Flex mt="auto">
                  <Button
                    title={t('Page.StorageUnit.Actions.Open')}
                    testId="open-with-pin-button"
                    onClick={handleOpenStorageUnit}
                    variant="primary"
                    size="xl"
                    disabled={isOpening}
                  />
                </Flex>
                {showEndReservation && showOptButton ? (
                  <Flex justifyContent="center" mt="4rem">
                    <EndCurrentReservation
                      storageUnitId={storageUnitIdResponse}
                    />
                  </Flex>
                ) : null}
                {showOptButton && (
                  <Flex mt="4rem">
                    <Button
                      title={t('Page.StorageUnit.Otp.Button')}
                      testId="otp-button"
                      onClick={handleClickShowOtp}
                      variant="primary"
                      size="xl"
                    />
                  </Flex>
                )}
                {showOtp ? (
                  <ShowOneTimePassword
                    storageUnitId={storageUnitId ?? ''}
                    oneTimePassword={otp}
                  />
                ) : null}
              </Flex>
            </Flex>
          ) : (
            <form onSubmit={handleSubmit(handleVerifyAccessPin)}>
              <Flex
                flexDirection="column"
                gap="6rem"
                alignItems="center"
                mt="6rem"
              >
                {formNotice && (
                  <Notification
                    testId="open-with-pin"
                    message={formNotice}
                    translationParams={
                      bindingFee ? { fee: bindingFee.toString() } : undefined
                    }
                  />
                )}
                <Heading
                  variant="h2"
                  title={t('Page.StorageUnit.Actions.Open.Verify.Heading')}
                />
                <Input
                  testId="open-with-pin-pin"
                  type={InputTypes.PASSWORD}
                  label={t('Page.StorageUnit.Actions.Open.Pin')}
                  placeholder={t('Page.StorageUnit.Actions.Open.Pin')}
                  error={
                    errors.accessPin?.message && t(errors.accessPin.message)
                  }
                  {...register('accessPin', {
                    required: 'Error.Form.Required',
                  })}
                />
                <Button
                  testId="verify-access-pin-button"
                  variant="primary"
                  size="xl"
                  title={t('Page.StorageUnit.Actions.VerifyAccessPin')}
                  disabled={isVerifying}
                  type="submit"
                />
              </Flex>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default OpenWithPin;
