import { Button, ButtonProps, ConfirmModal, Flex, Modal } from '@conteg/ui';
import { PolicyGuardWrapper } from 'components/policy-guard/policy-guard';
import { IS_KIOSK } from 'config';
import { OpenModalTypes } from 'pages/storage-unit-detail/components/actions/allocation-actions';
import { isAllocationExpired } from 'pages/storage-unit-list/help-functions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ServiceTypeEnum,
  StorageUnitListFragment,
  StorageUnitStatus,
} from 'types/generated/graphql';
import { PolicyAction } from 'types/policies-types';

import ActionsModalContent from './actions-modal-content';

export type OpenModal =
  OpenModalTypes.ForcedOpenStorageUnitWithReservation | null;

export type UnitStatus = StorageUnitStatus | undefined | null;

type Devices = 'web' | 'kiosk' | 'both';

type StorageUnitProps = {
  storageUnit: StorageUnitListFragment;
};

type Action = {
  id: string;
  label: string;
  onClick: () => void;
  availableOn: Devices;
  isAvailable?: boolean;
  variant: 'primary' | 'secondary' | 'danger';
  policyAction?: PolicyAction;
};

const isActionAvailable = (
  actionDevice: Devices,
  currentDevice: Devices,
  isAvailable?: boolean
): boolean => {
  return (
    (actionDevice === currentDevice || actionDevice === 'both') &&
    (isAvailable === undefined || isAvailable)
  );
};

const StorageUnitActions = ({ storageUnit }: StorageUnitProps) => {
  const { t } = useTranslation();

  const deviceType = IS_KIOSK ? 'kiosk' : 'web';

  const [openModal, setOpenModal] = useState<OpenModal>(null);
  const [showModalCloseConfirmation, setShowModalCloseConfirmation] =
    useState<boolean>(false);

  const [availableActions] = useState<Action[]>([
    {
      id: 'forced-open',
      label: 'Page.StorageUnit.Actions.ForcedOpen',
      policyAction: 'forcedStorageOpen',
      onClick: () =>
        setOpenModal(OpenModalTypes.ForcedOpenStorageUnitWithReservation),
      variant: 'danger',
      availableOn: 'both',
      isAvailable:
        isAllocationExpired(storageUnit.allocationTo) &&
        (storageUnit.serviceType === ServiceTypeEnum.BagToBox ||
          storageUnit.serviceType === ServiceTypeEnum.HandToHand),
    },
  ]);

  return (
    <>
      <Flex flexDirection="column" gap="3rem">
        <Flex gap="3rem">
          {availableActions
            .filter((action) =>
              isActionAvailable(
                action.availableOn,
                deviceType,
                action.isAvailable
              )
            )
            .map((action) => {
              const buttonProps: ButtonProps = {
                testId: `action=${action.id}`,
                variant: action.variant,
                size: 'l',
                title: t(action.label),
                onClick: action.onClick,
              };

              if (action.policyAction) {
                return (
                  <PolicyGuardWrapper
                    key={action.id}
                    action={action.policyAction}
                  >
                    <Button {...buttonProps} />
                  </PolicyGuardWrapper>
                );
              }
              return <Button key={action.id} {...buttonProps} />;
            })}
        </Flex>
      </Flex>
      <Modal
        testId="unit-actions-modal"
        size="xl"
        showCloseButton={true}
        isOpen={openModal !== null}
        onClose={() => setShowModalCloseConfirmation(true)}
      >
        <ActionsModalContent
          storageUnit={storageUnit}
          contentType={openModal}
        />
      </Modal>
      <ConfirmModal
        zIndexOffset={1000}
        modalTitle={t('Page.StorageUnit.Actions.CloseAction.ConfirmTitle')}
        modalDescription={t('Page.StorageUnit.Actions.CloseAction.ConfirmText')}
        isOpen={showModalCloseConfirmation}
        confirm={{
          text: t('Page.StorageUnit.Actions.CloseAction.ConfirmButton'),
          variant: 'danger',
        }}
        cancelText={t('Page.StorageUnit.Actions.CloseAction.CancelButton')}
        onClose={() => setShowModalCloseConfirmation(false)}
        onConfirm={() => {
          setOpenModal(null);
          setShowModalCloseConfirmation(false);
        }}
      />
    </>
  );
};

export default StorageUnitActions;
