import { Flex, Icon, Tooltip } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { StorageUnitFeature } from 'types/generated/graphql';

type StorageUnitFeaturesCompactProps = {
  storageUnitFeatures: StorageUnitFeature[] | null;
  isServiceModul: boolean;
};

const ICON_SIZE = '7rem';

const StorageUnitFeaturesCompact = ({
  storageUnitFeatures,
  isServiceModul,
}: StorageUnitFeaturesCompactProps) => {
  const { t } = useTranslation();

  const getTooltip = (feature: StorageUnitFeature) => {
    switch (feature) {
      case StorageUnitFeature.Cooling:
        return (
          <Tooltip
            testId="storage-unit-reservation-feature-tooltip-cooling"
            content={t('Page.StorageUnitList.Table.StorageUnitFeature.Cooling')}
            key={feature}
          >
            <Icon name="snowFlake" width={ICON_SIZE} height={ICON_SIZE} />
          </Tooltip>
        );
      case StorageUnitFeature.AcSocket:
        return (
          <Tooltip
            testId="storage-unit-reservation-feature-tooltip-acsocket"
            content={t(
              'Page.StorageUnitList.Table.StorageUnitFeature.AcSocket'
            )}
            key={feature}
          >
            <Icon name="chargingCable" width={ICON_SIZE} height={ICON_SIZE} />
          </Tooltip>
        );
    }
  };

  const getStandardBox = () => (
    <Tooltip
      testId="storage-unit-reservation-feature-tooltip-standard-box"
      content={t('Page.StorageUnitList.Table.StorageUnitFeature.StandardBox')}
      key="standard-box"
    >
      <Icon name="standardBox" width={ICON_SIZE} height={ICON_SIZE} />
    </Tooltip>
  );

  return (
    <Flex gap="1rem" alignItems="center">
      {storageUnitFeatures?.map(getTooltip) ??
        (!isServiceModul && getStandardBox())}
    </Flex>
  );
};

export default StorageUnitFeaturesCompact;
