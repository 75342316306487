export const getStatusTranslation = ({
  isEmpty,
  isExpired,
  isBlocking,
  canDisplayReservationPreview,
}: {
  isEmpty: boolean;
  isExpired: boolean;
  isBlocking: boolean;
  canDisplayReservationPreview: boolean;
}): string => {
  if (isEmpty) return 'Page.StorageUnitList.Table.Status.Empty';

  if (!canDisplayReservationPreview)
    return 'Page.StorageUnitList.Table.Status.Occupied';

  if (isExpired) return 'Page.StorageUnitList.Table.Status.Expired';
  if (isBlocking) return 'Page.StorageUnitList.Table.Status.Blocking';

  return 'Page.StorageUnitList.Table.Status.Occupied';
};
