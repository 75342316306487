import { authLinks, AuthRoutes, useAuth, useUserData } from '@conteg/auth';
import { Box, Flex, Navbar as NavbarConteg, styled, Text } from '@conteg/ui';
import {
  COMPANY_DETAIL_EXTERNAL_ROUTE,
  CUSTOM_AUTH_TOKEN,
  INSTALLATIONS_LIST_ROUTE,
} from 'config';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import env from 'utils/env/env';
import { openExternalLink } from 'utils/open-external-link/open-external-link';
import { appRoutes, substituteRouteParams } from 'utils/routing/routes';

const ContentWrapper = styled.div`
  width: min(100vw, 420rem);
  margin: 0 auto;
  flex: 1;
`;

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { signoutRedirect } = useAuth();
  const navigate = useNavigate();

  const userData = useUserData(CUSTOM_AUTH_TOKEN);

  const titles: Record<AuthRoutes, string> = {
    [AuthRoutes.CHANGE_PASSWORD]: t('Web.Layout.Navbar.ChangePassword'),
    [AuthRoutes.MY_PROFILE]: t('Web.Layout.Navbar.MyProfile'),
  };

  const authMenuLinks = authLinks.map((link) => ({
    label: titles[link.route],
    onClick: () => {
      window.open(`${env.VITE_IDENTITY_WEB_URL}${link.link}`, '_blank');
    },
  }));

  return (
    <NavbarConteg
      testId="navbar"
      logoLink={{
        label: t('Web.Layout.Navbar.LogoText'),
        onClick: () => navigate(appRoutes.home),
      }}
      navLinks={[
        {
          label: t('Web.Layout.Navbar.CompanyDetail'),
          onClick: () => {
            if (userData?.subjectId) {
              openExternalLink(
                substituteRouteParams(COMPANY_DETAIL_EXTERNAL_ROUTE, {
                  id: userData.subjectId,
                })
              );
            }
          },
        },
        {
          label: t('Web.Layout.Navbar.ManageInstallations'),
          onClick: () => {
            openExternalLink(INSTALLATIONS_LIST_ROUTE);
          },
        },
        {
          label: t('Web.Layout.Navbar.AuditWeb'),
          onClick: () => {
            openExternalLink(env.VITE_AUDIT_WEB_URL);
          },
        },
      ]}
      menuLinks={[
        ...authMenuLinks,
        {
          label: t('Web.Layout.Navbar.ToDeviceWeb'),
          onClick: () => window.location.assign(env.VITE_DEVICE_WEB_URL),
          icon: { name: 'grants' },
        },
        {
          label: t('Web.Layout.Navbar.Language'),
          onClick: () => {
            const newLang = i18n.language === 'en' ? 'cs' : 'en';
            i18n.changeLanguage(newLang);
          },
        },
        {
          label: t('Web.Layout.Navbar.Logout'),
          onClick: () => signoutRedirect(),
          icon: { name: 'logout' },
        },
      ]}
      user={{
        email: userData?.email || '',
        name: userData?.fullName || '',
      }}
    />
  );
};

const LayoutWeb = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="16px" height="100%">
      {(isAuthenticated || CUSTOM_AUTH_TOKEN) && <Navbar />}
      <ContentWrapper>{children}</ContentWrapper>
      <Box textAlign="center" padding="16px">
        <Text
          testId="footer"
          content={`${t('Web.Layout.Footer.Copy')} ${DateTime.now().year}`}
        />
      </Box>
    </Flex>
  );
};

export default LayoutWeb;
