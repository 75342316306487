import { Button, Flex, Modal, Text } from '@conteg/ui';
import { PolicyGuardWrapper } from 'components/policy-guard/policy-guard';
import StorageUnitFeaturesDetail from 'components/storage-unit-features-detail/storage-unit-features-detail';
import { DateTime } from 'luxon';
import ReservationLanguageDetail from 'pages/storage-unit-detail/components/actions/reservation-language-detail/reservation-language-detail';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BlockingReservationReason,
  ServiceType,
  StorageUnitDtoFragment,
} from 'types/generated/graphql';
import { formatDateTime, TimeFormats } from 'utils/time/format-time';

import ChangeReservationDuration from '../actions/change-reservation-duration/change-reservation-duration';

type ReservationDetailProps = {
  storageUnit: StorageUnitDtoFragment;
};

const AllocationDetail = ({ storageUnit }: ReservationDetailProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const allocation = storageUnit?.allocation;
  const hasBlockingReservation =
    storageUnit?.allocation?.reservation?.isBlockingReservation;
  const trackingIdentifiers = allocation?.content?.trackingIdentifiers
    ?.filter((ti) => ti.identifier)
    .map((ti) => ti.identifier)
    .join(', ');
  const phoneNumber =
    allocation?.reservation?.accessCredentials?.[0]?.phoneNumber;
  const senderPhoneNumber =
    allocation?.reservation?.accessCredentials?.[1]?.phoneNumber;
  const reservationId = allocation?.reservation?.id as string;
  const isDeliverySubmission = allocation?.reservation?.isDeliverySubmission;
  const blockingReservationReason =
    allocation?.reservation?.blockingReservationReason;
  const blockingReservationReasonDescription =
    allocation?.reservation?.blockingReservationReasonDescription;
  const requiredStorageUnitFeatures =
    allocation?.reservation.requiredStorageUnitFeatures;
  const language = allocation?.reservation.languageCode;
  const serviceType = allocation?.reservation.serviceType;
  const isNeverEndReservation = storageUnit.isExistingReservationNeverEnding;

  const isReservationForStockout = useCallback(() => {
    if (!allocation?.reservation?.to) return;

    const currentTime = DateTime.now();
    const reservationEndTime = DateTime.fromISO(allocation.reservation.to);

    return (
      currentTime > reservationEndTime ||
      ((blockingReservationReason ==
        BlockingReservationReason.UnidentifiedContent ||
        blockingReservationReason == BlockingReservationReason.WrongContent) &&
        serviceType == ServiceType.Delivery)
    );
  }, [allocation?.reservation?.to, serviceType, blockingReservationReason]);

  return (
    <Flex flexDirection="column" gap="3rem">
      {isNeverEndReservation && (
        <Flex alignItems="center" gap="3rem">
          <Text
            variant="highlight"
            content={t('Page.Reservation.NeverEndingReservation')}
          />
        </Flex>
      )}

      {!hasBlockingReservation && trackingIdentifiers !== undefined && (
        <Flex alignItems="center" gap="2rem">
          <Text
            variant="highlight"
            content={t('Page.Reservation.Id', {
              trackingIdentifier: trackingIdentifiers,
              allocationType: isDeliverySubmission
                ? t('Page.Reservation.IsDeliverySubmission')
                : t('Page.Reservation.CommonAllocation'),
            })}
          />
        </Flex>
      )}
      {(senderPhoneNumber || phoneNumber) && (
        <Flex flexDirection="column">
          <Text
            variant="highlight"
            content={t('Page.Reservation.PhoneNumber')}
          />
          {senderPhoneNumber && (
            <Flex gap="1rem">
              <Text
                variant="highlight"
                content={`${t('Page.Reservation.PhoneNumber.Sender')}: `}
              />
              <Text content={senderPhoneNumber} />
            </Flex>
          )}
          {phoneNumber && (
            <Flex gap="1rem">
              <Text
                variant="highlight"
                content={`${t('Page.Reservation.PhoneNumber.Recipient')}: `}
              />
              <Text content={phoneNumber} />
            </Flex>
          )}
        </Flex>
      )}
      {isReservationForStockout() && (
        <Text variant="highlight" content={t('Page.Reservation.Expired')} />
      )}
      {hasBlockingReservation && (
        <Text
          variant="highlight"
          content={t('Page.Reservation.BlockingReservation', {
            blockingReservationReason: t(blockingReservationReason ?? ''),
          })}
        />
      )}
      {blockingReservationReasonDescription && (
        <Text
          variant="highlight"
          content={t('Page.Reservation.BlockingReservationReasonDescription', {
            blockingReservationReasonDescription,
          })}
        />
      )}
      {allocation && !isNeverEndReservation && (
        <Text
          variant="large"
          content={t('Page.Reservation.Times', {
            start: formatDateTime(
              allocation?.reservation?.from,
              TimeFormats.dateTime
            ),
            end: formatDateTime(
              allocation?.reservation?.to,
              TimeFormats.dateTime
            ),
          })}
        />
      )}
      {allocation && !isNeverEndReservation && (
        <Flex gap="4rem" alignItems="center">
          <Text
            variant="large"
            content={t('Page.Reservation.TimesStockIn', {
              start: formatDateTime(
                allocation?.reservation?.expiration,
                TimeFormats.dateTime
              ),
            })}
          />
          <PolicyGuardWrapper action="shortenReservation">
            <Button
              testId="button-open-modal-update-reservation-duration"
              variant="primary"
              size="s"
              title={t('Button.UpdateReservationDuration')}
              onClick={() => setOpenModal(true)}
            />
          </PolicyGuardWrapper>
          <Modal
            testId="unit-actions-modal"
            size="l"
            showCloseButton={true}
            isOpen={openModal}
            onClose={() => setOpenModal(false)}
          >
            <ChangeReservationDuration
              reservationId={reservationId}
              from={allocation?.reservation?.from}
              to={allocation?.reservation?.to}
            />
          </Modal>
        </Flex>
      )}
      {allocation && !isNeverEndReservation && (
        <Text
          variant="large"
          content={t('Page.Reservation.TimeHandover', {
            time: formatDateTime(
              allocation?.reservation?.to,
              TimeFormats.dateTime
            ),
          })}
        />
      )}
      {language && <ReservationLanguageDetail languageCode={language} />}
      {requiredStorageUnitFeatures && (
        <StorageUnitFeaturesDetail
          onReservation={true}
          storageUnitFeatures={requiredStorageUnitFeatures}
        />
      )}
      <Text
        variant="large"
        content={
          <>
            <strong>{t('Page.Reservation.Company')}:</strong>
            {t('Page.Reservation.Company.Info', {
              name: storageUnit.company?.name,
              contact: storageUnit.phoneNumber,
            })}
          </>
        }
      />
      <Text
        variant="large"
        content={
          <>
            <strong>{t('Page.Reservation.ServiceType')}: </strong>
            {t(`Page.Reservation.ServiceType.${serviceType}`)}
          </>
        }
      />
    </Flex>
  );
};

export default AllocationDetail;
